
import React from 'react';
import Routes from './Routes';
import './styles.css';
import './styles/Header.css';
import { HelmetProvider } from 'react-helmet-async';
const App = () => {
  return (
    <HelmetProvider>
    <div className="app">
      <Routes />
    </div>
    </HelmetProvider>
  );
};
export default App;
