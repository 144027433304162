import React, { useState, useEffect, useContext, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from './Home/ThemeContext';
import PreloaderCircle from './components/PreloaderCircle';
import ProtectedRoute from './ProtectedRoute';
const Header = React.lazy(() => import('./Home/Header'));
const Footer = React.lazy(() => import('./Home/Footer'));
const Main = React.lazy(() => import('./Home/Main'));
const Login = React.lazy(() => import('./Home/Login'));
const HomePost = React.lazy(() => import('./Home/Post'));
const Register = React.lazy(() => import('./Home/Register'));
const AdminPanel = React.lazy(() => import('./Admin/AdminPanel'));
const Dashboard = React.lazy(() => import('./Admin/Dashboard'));
const Settings = React.lazy(() => import('./Admin/Settings'));
const Postlist = React.lazy(() => import('./Admin/Postlist'));
const Headerlist = React.lazy(() => import('./Admin/Headerlist.js'));
const SectionList = React.lazy(() => import('./Admin/SectionList.js'));
const Sections = React.lazy(() => import('./Admin/Sections.js'));
const Post = React.lazy(() => import('./Admin/Posts'));
const AdCreation = React.lazy(() => import('./Admin/AdCreation'));
const EditPost = React.lazy(() => import('./Admin/EditPost'));
const Users = React.lazy(() => import('./Admin/Users'));
const LandingPage = React.lazy(() => import('./Home/LandingPage'));
const NotFoundPage = React.lazy(() => import('./Home/NotFoundPage'));
const AdminLayout = React.lazy(() => import('./Admin/AdminLayout'));
const HeaderManagement  = React.lazy(() => import('./Admin/HeaderManagement'));
const FooterManagement  = React.lazy(() => import('./Admin/FooterManagement'));
const ContentSectionManagement  = React.lazy(() => import('./Admin/ContentSectionManagement'));


const App = () => {

  return (
    <HelmetProvider>
      <ThemeProvider>
        <Router>
          <Suspense fallback={<PreloaderCircle />}>
            <Routes>
              <Route path="/*" element={<Home />} />
              
              <Route path="/admin/*" element={<Admin />} />
              
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};


const Home = () => (
  <>
    <PreloaderCircle /> 
    <Suspense fallback={<div>Loading...</div>}>
      <div className='h-100 table w-100'>
        <Header/>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path=":title/" element={<HomePost />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer/>
      </div>
    </Suspense>
  </>
);

const Admin = () => (
  <>
    <Routes>
      <Route element={<AdminLayout />}>

        <Route path="/" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Dashboard /></Suspense></ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Settings /></Suspense></ProtectedRoute>} />
        <Route path="/ads" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><AdCreation /></Suspense></ProtectedRoute>} />
        <Route path="/footer" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><FooterManagement /></Suspense></ProtectedRoute>} />
        <Route path="/headers" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Headerlist /></Suspense></ProtectedRoute>} />
        <Route path="/add-header" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><HeaderManagement /></Suspense></ProtectedRoute>} />
        <Route path="/sections" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><SectionList/></Suspense></ProtectedRoute>} />
        <Route path="/add-section" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><ContentSectionManagement /></Suspense> </ProtectedRoute>} />
        <Route path="/posts" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Postlist /></Suspense> </ProtectedRoute>} />
        <Route path="/add-post" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Post /></Suspense> </ProtectedRoute>} />
        <Route path="/edit-post/:id" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><EditPost /></Suspense> </ProtectedRoute>} />
        <Route path="/users" element={<Users />} />
        <Route path="*" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><NotFoundPage /></Suspense> </ProtectedRoute>} />
      </Route>
    </Routes>
  </>
);

// NotFound Component
const NotFound = () => (
  <div className="not-found sections">
    <h1>404 - Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);

export default App;
